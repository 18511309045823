import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { BASE_URL } from '@/constants'

interface AuthState {
  memberships: Membership[]
  logOut: () => void
  setMemberships: (memberships: Membership[]) => void
  setOrg: (membership: Membership) => void
  org: string | undefined
  isAdmin: boolean
  fetchAPI: (
    path: string,
    params?: { [key: string]: string },
    options?: RequestInit,
  ) => Promise<any>
}

export const useAuth = create<AuthState>()(
  persist<AuthState>(
    (set) => ({
      memberships: [],
      org: undefined,
      isAdmin: false,
      setMemberships: (memberships) => {
        const org = memberships[0]

        set({
          memberships,
          org: org.hostname,
          isAdmin: org.role === 'administrator',
        })
      },
      logOut: () => {
        localStorage.clear()
        window.location.reload()
      },
      setOrg: (membership: Membership) => {
        set({
          org: membership.hostname,
          isAdmin: membership.role === 'administrator',
        })
      },
      fetchAPI: async (
        path: string,
        params: { [key: string]: string } = {},
        options: RequestInit = {},
      ) => {
        const url = new URL(path, BASE_URL)
        for (const key in params) {
          url.searchParams.append(key, params[key])
        }
        const session = localStorage.getItem('session')
        const response = await fetch(url, {
          ...options,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + session,
            ...(options.headers || {}),
          },
        })
        if (!response.ok) {
          if (response.status === 403) {
            localStorage.clear()
            window.location.reload()
          }
          if (
            response.headers.get('Content-Type')?.includes('application/json')
          ) {
            const data = await response.json()
            throw new Error(data.message)
          }
          throw new Error('An error occurred')
        }
        return response.json()
      },
    }),
    { name: 'authState' },
  ),
)
