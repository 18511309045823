import {
  Menu,
  MenuButton,
  MenuItem,
  MenuSeparator,
  MenuItems,
} from '@headlessui/react'
import * as React from 'react'

import { useAuth } from '../hooks/useAuth'

export const Header: React.FC = () => {
  const { memberships, org, setOrg } = useAuth()

  return (
    <div className="max-md:mb-[100px] mb-[330px] z-50">
      <header className="flex flex-wrap gap-5 leading-[34px] text-white h-[65px] justify-between px-[70px] fixed top-0 left-0 right-0 w-full max-md:px-5 max-md:max-w-full bg-gradient-to-r from-[#FD79FF] via-[#9737F8] to-[#977CFC]">
        <nav className="flex gap-[70px] items-center max-sm:hidden">
          <h1 className="self-stretch my-auto text-[28px] font-bold">
            ‹polymorphic›
          </h1>
        </nav>

        <div className="flex flex-wrap gap-[70px] items-center text-xl font-semibold ">
          <Menu>
            <MenuButton className="flex items-center h-full">
              <span>{org}</span>
            </MenuButton>
            <MenuItems anchor="bottom">
              <div className="p-[1px] mt-1 rounded-xl bg-gradient-to-r from-[#FD79FF] via-[#9737F8] to-[#977CFC] shadow-md">
                <div className="p-2 bg-black-main rounded-xl flex flex-col gap-2">
                  {memberships.length > 1 && (
                    <>
                      {memberships.map((membership) => (
                        <MenuItem key={membership.id}>
                          <button
                            className="block p-3 rounded-xl text-left border-transparent border-[1px] data-[focus]:border-white"
                            onClick={() => setOrg(membership)}
                          >
                            {membership.hostname}
                          </button>
                        </MenuItem>
                      ))}
                      <MenuSeparator />
                    </>
                  )}
                  <MenuItem>
                    <button
                      className="block p-3 rounded-xl text-left border-transparent border-[1px] data-[focus]:border-white"
                      onClick={() => {
                        localStorage.clear()
                        window.location.href = '/'
                      }}
                    >
                      Log Out
                    </button>
                  </MenuItem>
                </div>
              </div>
            </MenuItems>
          </Menu>
        </div>
      </header>
    </div>
  )
}
