import { createBrowserRouter } from 'react-router-dom'

import { BASE_URL } from '@/constants'
import { useAuth } from '@/hooks/useAuth'
import Notfound from '@/pages/404'
import Links from '@/pages/app'
import Landing from '@/pages/landing'

import NoAccount from './pages/noAccount'

const Protect = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactNode => {
  const auth = useAuth()
  let session = localStorage.getItem('session')

  const url = new URL(window.location.href)
  if (!session && url.searchParams.get('session')) {
    session = url.searchParams.get('session') as string

    localStorage.setItem('session', session)
  }

  if (!session) {
    window.location.assign(
      BASE_URL +
        '/auth/login?redirect_to=' +
        encodeURIComponent(window.location.href),
    )

    return null
  }

  if (!auth.memberships?.length) {
    auth.fetchAPI('/api/v1/users/memberships').then((data: any) => {
      auth.setMemberships(data.organizations)

      console.log(data)
    })
  }

  return children
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Landing />,
  },
  {
    path: '/app',
    element: (
      <Protect>
        <Links />
      </Protect>
    ),
  },
  {
    path: '/no-account',
    element: <NoAccount />,
  },
  {
    path: '*',
    element: <Notfound />,
  },
])

export default router
