import React from 'react'

type SectionProps = {
  children: React.ReactNode
}

const Section = ({ children }: SectionProps) => {
  return (
    <section className="flex mt-10 flex-col self-center gap-4 text-white max-w-[700px] w-[90%]">
      {children}
    </section>
  )
}

export default Section
